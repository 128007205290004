import React from "react";
import Table from "react-bootstrap/Table";

const Pricetable = () => {
  return (
    <div className="">
      <Table striped bordered hover className="mx-auto">
        <thead>
          <tr>
            <th>Количество Туристов</th>
            <th>Стоимость</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              Мини-группы <br /> (При наборе от 4-х чел по пятницам)
            </td>
            <td>
              $50 / чел <br />
            </td>
          </tr>
          <tr>
            <td>
              Индивидуальный тур <br />
              (До 4-х взрослых)
            </td>
            <td>$100/ чел</td>
          </tr>
          <tr>
            <td>
              Индивидуальный тур <br />
              (4 - 5 взрослых )
            </td>
            <td>$80 / чел</td>
          </tr>
          <tr>
            <td>
              Индивидуальный тур <br />
              (От 6-и человек )
            </td>
            <td>от $50 / чел</td>
          </tr>
        </tbody>
      </Table>
      <p>
        *В случае, если группа (4 чел) не наберется, организуем индивидуальную
        экскурсию по скидочной цене.
      </p>
    </div>
  );
};

export default Pricetable;
