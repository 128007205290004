import React from "react";
import Table from "react-bootstrap/Table";

const Pricetable = () => {
  return (
    <div>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Количество Туристов</th>
            <th>Стоимость</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              Групповый тур <br /> (По вторникам и пятницам)
            </td>
            <td>
              $90 / чел <br />
            </td>
          </tr>
          <tr>
            <td>
              Индивидуальный тур <br />
              (До 3-х взрослых)
            </td>
            <td>$185 / чел</td>
          </tr>
          <tr>
            <td>
              Индивидуальный тур <br />
              (4 - 5 взрослых )
            </td>
            <td>$145 / чел</td>
          </tr>
          <tr>
            <td>
              Индивидуальный тур <br />
              (От 5-и человек )
            </td>
            <td>от $85 / чел</td>
          </tr>
          <tr>
            <td>
              Русскоговорящий гид <br />
              (Для индивидуальных туров )
            </td>
            <td>$60</td>
          </tr>
        </tbody>
      </Table>
      <p>
        Туристам из Бентоты, Калутары, Диквеллы, Тангаллы и Васкадувы необходимо
        доплатить $20 на человека за трансфер до места начала экскурсии.
      </p>
    </div>
  );
};

export default Pricetable;
